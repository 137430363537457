import React from 'react';
import clsx from 'clsx';

import styles from './section-indicator.module.scss';

export function SectionIndicator({ sections, activeSection }) {
  return (
    <ul className={styles.wrapper}>
      {sections.map(({ id }) => (
        <li key={id}>
          <span className={clsx(styles.indicator, id === activeSection && styles.active)}></span>
        </li>
      ))}
    </ul>
  );
}
